import React from 'react';

import { FooterContainer, FooterTitle, FooterInfo } from './footer.styles';
import packageJson from '../../../package.json';




const Footer = () => {
    const date = new Date();

    return(
        <FooterContainer>
            {/* <a href="https://www.freepik.es/fotos-vectores-gratis/comida">Vector de Comida creado por freepik - www.freepik.es</a> */}
            <FooterTitle>
                © {date.getFullYear()} Copyright: SoftRuss.Todos los derechos reservados.
            </FooterTitle>
            <FooterInfo>
                 {(process.env.NODE_ENV !== 'production') ? `La Palma Site V ${packageJson.version} Env:${process.env.REACT_APP_ENV}` : null}
            </FooterInfo>
        </FooterContainer>
        
    )
  
}

export default Footer;