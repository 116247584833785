
const MenuActionTypes = {
    
    GET_DAILY_MENU_START: 'GET_DAILY_MENU_START',
    GET_DAILY_MENU_SUCCESS: 'GET_DAILY_MENU_SUCCESS',
    GET_DAILY_MENU_FAILURE: 'GET_:DAILY_MENU_FAILURE',
    GET_MENU_START: 'GET_MENU_START',
    GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
    GET_MENU_FAILURE: 'GET_MENU_FAILURE',
    VERIFY_IS_OPEN: 'VERIFY_IS_OPEN'
    
  };
  
  export default MenuActionTypes;