import React from "react";
import { RouteComponentProps, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { unauthRoutes, userRoles, authRoutes } from "../utils/constanst.utils";
import { selectCurrentUser } from "../redux/user/user.select";

const AuthRoute = ({ component: Component, path, exact = false, user, requiredRoles }) => {
  console.log("CurrentUser====> AuthRoute===>", user);
  console.log("Path=====>", path);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        

        if (user) {
           console.log('RequiredRoles====>', requiredRoles)
        const userHasRequiredRole = requiredRoles.includes(user.role);
        console.log("userHasRequiredRole", userHasRequiredRole);
        if(user.auth && userHasRequiredRole){
            return <Component {...props} />;
        }else{
            return  <Redirect to={authRoutes.unauth} />;
        }  
        } else {
          return <Redirect to={unauthRoutes.login} />;
        }
      }}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(AuthRoute);
