import styled, {keyframes} from 'styled-components';


const animbase = keyframes`
{
  0% {
    transform: scale(1) rotate(0deg);
  }
  40% {
    transform: scale(1.5) rotate(0deg);
  }

  70% {
    transform: scale(1.5) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
`;

export const LoadingLogoLay = styled.div`
display: flex;
justify-content: center;
align-items: center;
z-index: 100;
background-color: rgba(0,0,0,0.9);
height: 100vh;
width: 100vw;




`;

export const LoadingContainer = styled.div`
  display: inline-block;
  width: 12rem;
  & svg {
    width: 12rem;
    transform-origin: center 40%;
    animation: ${animbase} 2s ease infinite forwards
  }
  h5{
    margin: auto;
  }
`;