import {takeLatest, call, put, all} from 'redux-saga/effects';

import {firestore, convertCollectionsSnapshotToMap, getPhotoURL} from '../../firebase/firebase.utils';

import {getDailyMenuSuccess, getDailyMenuFailure, getMenuSuccess, getMenuFailure} from './menu.actions';

import MenuActionTypes from './menu.types';

export function* getDailyMenuStart () {
    yield takeLatest(MenuActionTypes.GET_DAILY_MENU_START, getDailyMenuAsync);
}


export function* getDailyMenuAsync () {
    try {
        const collectionRef = firestore.collection('menu');
        collectionRef.orderBy('name','desc')
        const snapshot = yield collectionRef.where('show_daily', '==', true).get();
        console.log('project FIREBASE', process.env.REACT_APP_PROJECT_ID)
        console.log('SNAPSHOT====>', snapshot)
        const menuMap = yield call(convertCollectionsSnapshotToMap, snapshot);
        //const menu = yield call(getPhotoURL, menuMap)
        yield put(getDailyMenuSuccess(menuMap));
    } catch (error) {
        yield put(getDailyMenuFailure(error.message));
    }
}

export function* getMenuStart () {
    yield takeLatest(MenuActionTypes.GET_MENU_START, getMenuAsync);
}


export function* getMenuAsync () {
    try {
        const collectionRef = firestore.collection('menu');
       
        collectionRef.where('show_menu', '==', true).orderBy('name','asc').limit(50)
        const snapshot = yield collectionRef.get();
    
        const menuMap = yield call(convertCollectionsSnapshotToMap, snapshot);
       // const menu = yield call(getPhotoURL, menuMap)
        yield put(getMenuSuccess(menuMap));
    } catch (error) {
        yield put(getMenuFailure(error.message));
    }
}


export function* menuSagas () {
    yield all([call(getDailyMenuStart), call(getMenuStart)]);
}
