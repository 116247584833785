import styled from 'styled-components';


export const FooterContainer = styled.div`
    display: flex;
    width: 100%;
    background-color: #C44400;
    color: #ffffff;
    position: fixed;
    padding: none;
    box-sizing: border-box;
    bottom: 0;
    height: 30px;
`;


export const FooterTitle = styled.div`
    position: relative;
    margin: auto;

`;

export const FooterInfo = styled.div`
    display: flex;

`;
