import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import orderReducer from './order/order.reducer';
import menuReducer from './menu/menu.reducer';


const rootReducer = combineReducers({
    user: userReducer,
    order: orderReducer,
    menu: menuReducer,

   
  });


  export default rootReducer;