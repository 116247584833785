import React from "react";

import { ReactComponent as LaPalmaLogo } from "../../assets/img/Lapalmalogo.svg";


import { LoadingLogoLay, LoadingContainer } from "./spinner.styles.jsx";

const Spinner = () => (
    <LoadingLogoLay>
        <LoadingContainer>
        <LaPalmaLogo/>
            <h5>Cargando...</h5>
        </LoadingContainer>
   
  </LoadingLogoLay>
);

export default Spinner;
