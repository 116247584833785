import React, {useState, useEffect} from "react";

import {CSSTransition} from "react-transition-group";
import {Link} from "react-router-dom";


import {ReactComponent as LaPalmaLogo} from "../../assets/img/Lapalmalogo.svg";
import {ReactComponent as HamBar} from "../../assets/img/bars-solid.svg";

import {Head, Nav, InfoHead} from './header.styles'


export default function Header() {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return() => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    return (
        <>
         
            <Head>
           
                <LaPalmaLogo style={
                    {width: '10rem'}
                }/>
                <CSSTransition in={
                        !isSmallScreen || isNavVisible
                    }
                    timeout={350}
                    classNames="NavAnimation"
                    unmountOnExit>
                    <Nav>
                        <Link to="/home">Menú del día</Link>
                        <Link to="/menu">A la carta</Link>
                        <Link to="/delivery">Zona de entrega y teléfono</Link>
                        {/* <button>Logout</button> */} </Nav>
                </CSSTransition>
                <button onClick={toggleNav}>
                    <HamBar/>
                </button>
            </Head>
        </>
    );
}
