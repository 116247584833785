import MenuActionTypes from './menu.types';



export const getDailyMenuStart = () => ({
  type: MenuActionTypes.GET_DAILY_MENU_START
});

export const getDailyMenuSuccess = (dailyMenuMap) => ({
  type: MenuActionTypes.GET_DAILY_MENU_SUCCESS,
  payload: dailyMenuMap
});

export const getDailyMenuFailure = error => ({
  type: MenuActionTypes.GET_DAILY_MENU_FAILURE,
  payload: error
});

export const getMenuStart = () => ({
  type: MenuActionTypes.GET_MENU_START
});

export const getMenuSuccess = (MenuMap) => ({
  type: MenuActionTypes.GET_MENU_SUCCESS,
  payload: MenuMap
});

export const getMenuFailure = error => ({
  type: MenuActionTypes.GET_MENU_FAILURE,
  payload: error
});

export const verifyIsOpen = () => ({
  type: MenuActionTypes.VERIFY_IS_OPEN,
  payload: isOpen()
})


export const isOpen = () => {
  const day = new Date();

  if(day.getDay() !== 0 ) {
    if(day.getHours() > 8 && day.getHours() < 18 ){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
    
 
  

}