import styled, {createGlobalStyle} from 'styled-components';


export const Body = styled.div `
    
`;

export const GobalStyle = createGlobalStyle `

    root:{

    }

    body{  
        font-family: -apple-s;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
        color: #ffffff;
        margin: 0;
        padding: 0; 
        height: 100%; 
        background: linear-gradient(to bottom right, #FF512F, #F09819);
        background-repeat: no-repeat;
        background-attachment: fixed;  
    }

    div {
        
    }

    body:: -webkit-scrollbar{
        width: 0.25rem;
    }

    body::-webkit-scrollbar-track{
        background: #1e1e24;
    }

    body::-webkit-scrollbar-thumb{
        background: #6649b8;
    }


    .othersadsas{
        padding: 20px 80px;
        @media screen and (max-width : 800px){
            padding: 10px;
        }
    }
    
    a{
        text-decoration: none;
        color: white
    }
  

    & .ReactModal__Content {
    transition: all 0.3s;
    opacity: 0;
        
    transform: translateY(-400px);
    }

    & .ReactModal__Content--after-open {
    opacity: 1;
    transform: translateY(0);
    }

    & .ReactModal__Content--before-close {

    transform: translateY(-600px);
        opcaity: 0;
    }
    
     
   
`;
