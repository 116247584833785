import React, {lazy, Suspense, useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

import { ReactComponent as Molcajete } from "./assets/img/molcajete.svg";


// components
import Footer from "./components/footer/footer.component";
import Header from "./components/header/header.component";
import Spinner from "./components/spinner/spinner.component.jsx";

import AuthRoute from "./routes/authRoutes";

import {selectCurrentUser} from "./redux/user/user.select";
import {checkUserSession} from "./redux/user/user.actions";
import {verifyIsOpen} from './redux/menu/menu.actions'

import {authRoutes, unauthRoutes, userRoles} from "./utils/constanst.utils";

// Styles
import {GobalStyle, Body} from "./global.styles";

// Pages
const Home = lazy(() => import ("./pages/home/home.component"));
const Delyvery = lazy(() => import ("./pages/delivery/delivery.component"));
const Unauthorized = lazy(() => import ("./pages/unathorized/unauthorized.component"));
const MenuPage = lazy(() => import ("./pages/menu/menu.component"));
const Error404 = lazy(() => import ("./pages/404/404.component"));


const NavRoutes = [
   {
        path: unauthRoutes.home,
        route: Route,
        component: Home,
        exact: true
    }, {
        path: unauthRoutes.menu,
        route: Route,
        component: MenuPage,
        exact: true
    },
    {
      path: unauthRoutes.delivery,
      route: Route,
      component: Delyvery,
      exact: true
  }
];


const App = ({checkUserSession, currentUser, checkIsOpen}) => {
    useEffect(() => { // console.log('CHECKUSERSESSION!!!!!!', checkUserSession, currentUser)
        checkUserSession();
    }, [checkUserSession]);
    useEffect(() => { // console.log('CHECKUSERSESSION!!!!!!', checkUserSession, currentUser)
    checkIsOpen();
}, [checkIsOpen]);

    console.log('Project id FIREBASE', process.env.REACT_APP_PROJECT_ID)

    return (
        <Body>
                {/* <div style={{position: "relative"}}>
                <Molcajete  style={{position: "absolute", width:'8rem'}}/>
            </div> */}
            <GobalStyle/>
    
            <Header/>

            <Switch>
                <Suspense fallback={<Spinner/>}>
                  <Route path='/' component={Home} exact={true}/>
                    {
                    NavRoutes.map((item) => (
                        <item.route path={
                                item.path
                            }
                            component={
                                item.component
                            }
                            exact={
                                item.exact
                            }
                            user={currentUser}
                            requiredRoles={
                                item.requiredRoles
                            }/>
                    ))
                }
                    {" "} </Suspense>
            </Switch>
            <Footer/>{" "} </Body>
    );
};

const mapStateToProps = createStructuredSelector({currentUser: selectCurrentUser});

const mapDispatchToProps = (dispatch) => ({
    checkUserSession: () => dispatch(checkUserSession()),
    checkIsOpen: () => dispatch(verifyIsOpen())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
