import MenuActionsType from './menu.types'

const INITIAL_STATE = {
    menuDaily: null,
    menuItems: null,
    isOpen: false,
    loading: false,
    error: undefined

};

const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MenuActionsType.GET_MENU_START:
        case MenuActionsType.GET_DAILY_MENU_START:
            return {
                ...state,
                loading: true
            }
        case MenuActionsType.GET_DAILY_MENU_SUCCESS:
            return {
                ...state,
                menuDaily: action.payload,
                loading: false,
                error: null
            }
        case MenuActionsType.GET_MENU_SUCCESS:
            return {
                ...state,
                menuItems: action.payload,
                loading: false,
                error: null
            }
        case MenuActionsType.VERIFY_IS_OPEN:
            return {
                ...state,
                isOpen: action.payload
            }
        case MenuActionsType.GET_MENU_FAILURE:
        case MenuActionsType.GET_DAILY_MENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default orderReducer
