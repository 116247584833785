import styled from 'styled-components';


export const Head = styled.header `
position: fixed;
  top: 0; /* Stick it to the top */
  max-height: 70px;
  width: 100vw;

  display: grid;
  grid-area: header;
  grid-template-areas: "logo nav";

  /* Cosmetics */
  
background-color: #A72E00;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

svg {
    grid-area: logo;
    height: 70px;
}


button {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 30px;
    height: 50px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
    
    &:active {
        transform: scale(.8);
      }
    svg{
      height: 65px;
    }
    path{
        fill: white;
    }
  }
  
  
  @media (max-width: 700px) {
    
      grid-template-areas: "logo burger" "nav nav";
    
    nav {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: none;
      grid-row-gap: 0px;
      width: 100%;
      padding: 30px 0 30px;
      background: rgba(147, 44, 0, 0.95);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    button {
      display: inline;
    }
  }

  .NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
  }
  .NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
  }
  .NavAnimation-exit {
    opacity: 1;
  }
  .NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
  }
  
`;

export const Nav = styled.nav `
display: grid;
  grid-area: nav;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-items: center;

  a {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;

    &:hover{
        transform: scale(1.1);
    }
  }
`;

export const InfoHead = styled.header `


`; 