

export const roles =  {
    Aministrador : 'ADMIN_PALMA',
    Gerente : 'MANAGER_PALMA',
    Chef : 'CHEF_PALMA',
    Mesero : 'WAITER_PALMA',
    Repartidor : 'DELIVERY_PALMA',
    Preparador : 'BAR_PALMA'

}

export const userRoles = {
    admins: [roles.Aministrador],
    manager: [roles.Gerente],
    chef: [roles.Chef],
    all:[roles.Aministrador,roles.Gerente,roles.Preparador, roles.Repartidor, roles.Mesero]
}


export const authRoutes ={
    home: '/home',
    menu: '/menu',
    unauth: '/error401/unauthorized'
}

export const unauthRoutes = {
    home: '/home',
    menu: '/menu',
    contact: '/contact',
    delivery: '/delivery',
    resetPass: '/resetPassword'
}

// export const NavRoutes = [
//     {
//       path: unauthRoutes.login,
//       route: Route,
//       component: LoginPage,
//       exact: true
//     },
//     {
//       path: authRoutes.dashboard,
//       route: AuthRoute,
//       component: Dashboard,
//       exact: true,
//       requiredRoles: [userRoles.all]
//     },
//     {
//       path: authRoutes.menu,
//       route: AuthRoute,
//       component: Menu,
//       exact: true,
//       requiredRoles: [userRoles.admins,userRoles.manager]
//     },
//     {
//       path: authRoutes.unauth,
//       route: Route,
//       component: Unauthorized,
//       exact: true
//     },
//   ];