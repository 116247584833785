import OrderActionsType from './order.types'

const INITIAL_STATE = {
    show: false,
    orderItems: []
};

const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OrderActionsType.TOGGLE_MODAL_ORDER:
            return {
                ...state,
                show: !state.show
            }
        default:
            return state;
    }
}

export default orderReducer