import firebase, { storage } from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'


const config = {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_APP_ID}`,
    measurementId:`${process.env.REACT_APP_MEASUREMENT_ID}`
};

export const getDailyMenu = async () => {
  const ref = firestore.doc()
}

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) 
        return;
    console.log('userAuth===>', userAuth)
    const userRef = firestore.doc(`users/${
        userAuth.uid
    }`);

    const snapShot = await userRef.get();
    console.log('SnapShot=', snapShot)
    if (! snapShot.exists) {
        const {displayName, email, photoURL} = userAuth;
        const createdAt = new Date();

        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                photoURL,
                role: 'admin',
                auth: false,
                ...additionalData
            })
        } catch (error) {
            console.error('Error creating user ', error.message)
        }
    }
    return userRef;
}



//Realizar carga de datos
// export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) =>{
//     const collectionRef = firestore.collection(collectionKey);
//     console.log('REF===', collectionRef)

//     const batch = firestore.batch()
//     objectsToAdd.forEach(element => {
//         const newDocRef = collectionRef.doc()
//         console.log(newDocRef)
//         batch.set(newDocRef, element)
//     });
//     return await batch.commit()
// }



export const convertCollectionsSnapshotToMap = (collectionsSnapshot) =>{
    const tranformedCollection = collectionsSnapshot.docs.map(doc=>{
        const { name, description, item_type, photo, photo_URL } = doc.data()
        const id = doc.id
        return{
            id,
            name,
            description,
            item_type,
            photo,
            photo_URL
        };
    }); 

    tranformedCollection.sort(function(a, b){
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
    })
    return tranformedCollection
}

export const getPhotoURL =  async (menu) =>{
    const menuStorageRef = storage().ref('/menu');
    const transformedMenu =  menu.map( (item) =>{
        if(item.photo){
            const url=  menuStorageRef.child(`${item.id}.jpg`).getDownloadURL().then(data=> console.log('DATAURL===>',data))
            console.log('Item Photo', item.photo_URL)

            
                item.photo_URL = url
      

        }
    })
    return transformedMenu

}

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({prompt: 'select_account'});
export const signInwithGoogle = () => auth.signInWithPopup(googleProvider);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth  => {
      unsubscribe( );
      resolve(userAuth);
    },reject => {
      
    })
  });
}


export default firebase;
